import React from 'react';
import {createBrowserRouter, Navigate, Route, Routes} from 'react-router-dom';

import Login from './views/auth/Login';
import Admin from "./layouts/Admin";
import useToken from "./components/App/useToken";
import {RouterProvider} from "react-router";
import OrdersTable from "./components/Orders/OrdersTable";
import StatusTable from "./components/Status/StatusTable";
import DeadLetterTable from "./components/DeadLetter/DeadLetterTable";
import AuditTable from "./components/Audit/AuditTable";
import WebhooksTable from "./components/Webhooks/WebhooksTable";
import UsersTable from "./components/User/UserTable";
import UserGroupsTable from "./components/User/UserGroupTable";
import OrdersDetail from "./components/Orders/OrdersDetail";

function Root() {
    return (
        <Routes>
            <Route element={<Admin />}>
                <Route path="/admin/orders" element={<OrdersTable />} />
                <Route path="/admin/orders/:id" element={<OrdersDetail />} />
                <Route path="/admin/status" element={<StatusTable />} />
                <Route path="/admin/deadletter" element={<DeadLetterTable />} />
                <Route path="/admin/audit" element={<AuditTable />} />
                <Route path="/admin/webhooks" element={<WebhooksTable />} />
                <Route path="/admin/users" element={<UsersTable />} />
                <Route path="/admin/usergroups" element={<UserGroupsTable/>} />
                <Route path="/admin" element={<Navigate to="/admin/status" />} />
            </Route>
            <Route path="*" element={<Navigate to="/admin" replace={true} />} />
        </Routes>
    );
}

const router = createBrowserRouter([
    { path: "*", element: <Root /> },
]);

function App() {
    const { token, setToken } = useToken();

    if(!token) {
        return <Login setToken={setToken} />
    }

    return <RouterProvider router={router} />;
}

export default App;