import React, {useEffect, useState} from "react";
import {Url} from "../../constants/global";

// components

import BaseView from "../../views/admin/BaseView";
import {useParams} from "react-router";

const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token
}

function Badge(props) {
    let classContent="";
    if(props.type === "success")
        classContent = "bg-green-100 text-green-800 border border-green-400";
    else if(props.type === "warning")
        classContent = "bg-yellow-100 text-yellow-800 border border-yellow-300";
    else if(props.type === "danger")
        classContent = "bg-red-100 text-red-800 border border-red-400";
    return (
        <span className={"text-xs font-medium me-2 px-2.5 py-0.5 rounded " + classContent}>{props.children}</span>
    )
}

function BaseElement(props) {
    return (
        <div className="container grid grid-cols-11 border border-sky-600 m-5 rounded-lg">
            <div className="p-2 bg bg-sky-600 text-white rounded-l-lg">{props.type} {props.badge}
            </div>
            <div className="col-span-10 p-2 w-full">
                <div className="date float-right">{props.createdAt}</div>
                {props.content}
            </div>
        </div>
    )
}

function OrderCri(props) {
    const {item} = props;
    let content;
    if(item.cri_type === "SEQ_SDA")
        content = <div className={"sdasec mt-2"}>
            <textarea name="" id="" cols="30" rows="10">{item.sda_sequence.replace('^', '\n')}</textarea>
        </div>
    else
        content = <div className={"row mt-2"}>
            <div className="col-lg-4">
                <div className="card card-info">
                    <div className="field">DATE CRI</div>
                    <div
                        className="info">{item.created_at}</div>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="card card-info">
                    <div className="field">DATE PROPOSÉE</div>
                    <div
                        className="info">{item.porta_date}</div>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="card card-info">
                    <div className="field">DATE REEL</div>
                    <div
                        className="info">{item.real_porta_date}</div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="card card-info">
                    <div className="field">Commentaire</div>
                    <div className="info">{item.opd_comment}</div>
                </div>
            </div>
        </div>

    return (
        <BaseElement
            type={"CRI"}
            badge={<Badge type={"warning"}>{item.cri_type}</Badge>}
            content={content}
            createdAt={item.created_at}
        />
    )
}

function OrderCr(props) {
    const {item, errorCodes} = props;
    let error = null;
    if (errorCodes) {
        let errorCode = errorCodes.find((err) => err.code.includes(item.reject_reason_cr));
        if(errorCode)
            error = errorCode.descript;
    }

    return (
        <BaseElement
            type={"CR"}
            badge={<Badge type={item.CR_type === "OK" ? "success" : "danger"}>{item.CR_type}</Badge>}
            content={<>{item.CR_type !== "OK" && <div className="text">Code d'erreur : {error} {item.reject_reason_cr}</div>}
                <div className="text">Commentaire Opérateur : { item.opd_comment_cr}</div>
            </>}
            createdAt={item.created_at}
        />
    )
}

function OrderAr(props) {
    const {item, errorCodes} = props;
    let error = null;
    if (errorCodes) {
        let errorCode = errorCodes.find((err) => err.code.includes(item.reject_reason_ar));
        if(errorCode)
            error = errorCode.descript;
    }
    return (
        <BaseElement
            type={"AR"}
            badge={<Badge type={item.AR_type === "OK" ? "success" : "danger"}>{item.AR_type}</Badge>}
            content={<div className="text"> ID: {item.internal_ref_opd_ar}
                {item.AR_type !== "OK" && <><br/>Error : {error} (Code {item.reject_reason_ar})</>}
            </div>}
            createdAt={item.created_at}
        />
    )
}

function OrderAnn(props) {
    const {item} = props;
    return (
        <BaseElement
            type={""}
            badge={<Badge type={item.type === "REAPTG" ? "success" : "warning"}>{item.type}</Badge>}
            content={item.number.map((n) => <>{n}<br/></>)}
            createdAt={item.created_at}
        />
    )
}

function OrderSuborder(props) {
    const {item} = props;
    return (
        <BaseElement
            type={item.request}
            badge={""}
            content={item.request === "ANNU" ? <Badge type={"danger"}>Annulation de la portabilité</Badge> : (
                item.request === "ACQ_FIAB" ?
                    <Badge type={item.reliability === "OK" ? "success" : "danger"}>{item.reliability}</Badge> :
                    <>Au : {item.wanted_date}</>
            )}
            createdAt={item.created_at}
        />
    )
}

function clearNd(item){
    return item ? (item.includes('#') ? item.split('#')[0] : item) : '';
}

function getRio(item){
    if(item.grouped_numbers_list)
        return clearNd(item.grouped_numbers_list);
    else
        return clearNd(item.isolated_numbers_list)
}

function OrdersDetail({color = 'light'}) {
    let params = useParams();
    const [item, setItem] = useState({});
    const [itemCR, setItemCR] = useState([]);
    const [itemAR, setItemAR] = useState([]);
    const [itemCRI, setItemCRI] = useState([]);
    const [itemANN, setItemANN] = useState([]);
    const [itemSuborder, setItemSuborder] = useState([]);
    const [elements, setElements] = useState([]);
    const [errorCodes, setErrorCodes] = useState([]);

    const identifyElement = (item) => {
        if(itemCR && itemCR.includes(item))
            return 'itemCR';
        else if(itemAR && itemAR.includes(item))
            return 'itemAR';
        else if(itemCRI && itemCRI.includes(item))
            return 'itemCRI';
        else if(itemANN && itemANN.includes(item))
            return 'itemANN';
        else if(itemSuborder && itemSuborder.includes(item))
            return 'itemSuborder';
        else return 'unknown';
    }

    useEffect(()=>{
        fetch(Url + '/order/'+params.id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            }
        }).then(res => res.json()).then((data) => {
            setItem(data);
        })

        fetch(Url + '/mopErrorCode/?offset=0&limit=260', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            }
        }).then(res => res.json()).then((data) => {
            setErrorCodes(data.results);
        })
    }, [params.id]);

    useEffect(()=>{
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken(),
            'Access-Control-Allow-Origin': '*'
        };
        Promise.all([
            fetch(Url + '/order/'+params.id+'/cri', {headers}),
            fetch(Url + '/order/'+params.id+'/cr', {headers}),
            fetch(Url + '/order/'+params.id+'/ar', {headers}),
            fetch(Url + '/order/'+params.id+'/ann', {headers}),
            fetch(Url + '/suborder/'+params.id, {headers})
        ]).then(([resCri, resCr, resAr, resAnn, resSuborder]) => {
            return Promise.all([
                resCri.json(),
                resCr.json(),
                resAr.json(),
                resAnn.json(),
                resSuborder.json()
            ])
        }).then(([
            itemCri, itemCr, itemAr, itemAnn, itemSuborder])=> {
            setItemCRI(itemCri.results);
            setItemCR(itemCr.results);
            setItemAR(itemAr.results);
            let anns = [];
            itemAnn.results.map((itm)=>{
                let tmpAnn = {'type': itm.type, 'ref': itm.ref, 'number':[itm.number+'#'+itm.nb_numbers],'pfx': itm.pfx, 'created_at': itm.created_at.split('.')[0]};
                let search = anns.findIndex((f) => f.type === itm.type && f.ref === itm.ref && f.pfx === itm.pfx && f.created_at === itm.created_at.split('.')[0]);
                if (search !== -1) {
                    anns[search].number.push(itm.number+'#'+itm.nb_numbers);
                }else{
                    anns.push(tmpAnn);
                }
                return null;
            });
            setItemANN(anns);
            setItemSuborder(itemSuborder.results);

            let result = [].concat(itemCri.results, itemCr.results, itemAr.results, anns, itemSuborder.results);
            result.sort((a, b)=>{
                let firstDate = Object.prototype.hasOwnProperty.call(a, 'createdAt') ? a.createdAt : a.created_at;
                let secondDate = Object.prototype.hasOwnProperty.call(b, 'createdAt') ? b.createdAt : b.created_at;

                return (firstDate < secondDate) ? 1 : -1
            });
            setElements(result);
        })
    }, [item, params.id]);

    if (!item || Object.keys(item).length === 0 ) return null;

    return (
        <BaseView>
            <div
                className={
                    "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                    (color === "light" ? "bg-white" : "bg-sky-900 text-white")
                }
            >
                <div className="rounded-t mb-0 px-4 py-3 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3
                                className={
                                    "font-semibold text-lg " +
                                    (color === "light" ? "text-slate-700" : "text-white")
                                }
                            >
                                Order {item.id} | Ref {item.ref}
                            </h3>
                        </div>
                    </div>
                </div>
                <div className={"flex p-5 space-x-5"}>
                    <div className={"flex-auto"}>
                        <div
                            className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6">
                            <h5 className="mb-3 text-base font-semibold text-gray-900">
                                Portabilité
                            </h5>
                            <ul className="my-4 space-y-3">
                                <li>
                                    <span className="flex-1 ms-3 whitespace-nowrap">OPA : {item.opa}</span>
                                </li>
                                <li>
                                    <span className="flex-1 ms-3 whitespace-nowrap">OPE : {item.ope}</span>
                                </li>
                                <li>
                                    <span className="flex-1 ms-3 whitespace-nowrap">OPR : {item.opr}</span>
                                </li>
                                <li>
                                    <span className="flex-1 ms-3 whitespace-nowrap">Ordre : {item.request}</span>
                                </li>
                                <li>
                                    <span
                                        className="flex-1 ms-3 whitespace-nowrap">Destinataire : {item.ref.includes('A') ? item.opa : item.ope}</span>
                                </li>
                                <li>
                                    <span className="flex-1 ms-3 whitespace-nowrap">Statut : {item.status}</span>
                                </li>
                                <li>
                                    <span
                                        className="flex-1 ms-3 whitespace-nowrap">Type de porta : {item.portability_type}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={"flex-auto"}>
                        <div
                            className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6">
                            <h5 className="mb-3 text-base font-semibold text-gray-900">
                                Portabilité
                            </h5>
                            <ul className="my-4 space-y-3">
                                <li>
                                    <span className="flex-1 ms-3 whitespace-nowrap">NDI : {getRio(item)}</span>
                                </li>
                                <li>
                                    <span className="flex-1 ms-3 whitespace-nowrap">RIO : {item.isolated_numbers_list && item.isolated_numbers_list.includes('#') ? (
                                        item.isolated_numbers_list.split('#').length >= 3 ? item.isolated_numbers_list.split('#')[2] : ''): ''}</span>
                                </li>
                                <li>
                                    <span
                                        className="flex-1 ms-3 whitespace-nowrap">Séquence SDA : {item.sda_sequence} {item.other_sda_sequence} {item.other_sda_sequence2} {item.other_sda_sequence3}</span>
                                </li>

                                <li>
                                    <span
                                        className="flex-1 ms-3 whitespace-nowrap">Option Fiabilisation : {item.reliability_option === 1 ? "Oui" : "Non"}</span>
                                </li>
                                <li>
                                    <span
                                        className="flex-1 ms-3 whitespace-nowrap">Acquittement Fiabilisation : {item.reliability_option === 1 ? item.reliability : "--"}</span>
                                </li>
                                <li>
                                    <span
                                        className="flex-1 ms-3 whitespace-nowrap">Mode de validation : {item.simplified_process_option === 1 ? "Auto" : "Par Appel"}</span>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div className={"flex-auto"}>
                        <div
                            className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6">
                        <h5 className="mb-3 text-base font-semibold text-gray-900">
                                Portabilité
                            </h5>
                            <ul className="my-4 space-y-3">
                                <li>
                                    <span
                                        className="flex-1 ms-3 whitespace-nowrap">Date souhaitée : {item.wanted_date}</span>
                                </li>
                                <li>
                                    <span
                                        className="flex-1 ms-3 whitespace-nowrap">Date proposé par l'OP : {item.real_porta_date}</span>
                                </li>
                                <li>
                                    <span
                                        className="flex-1 ms-3 whitespace-nowrap">Plage horaire : {item.time_slot}</span>
                                </li>
                                <li>
                                    <span
                                        className="flex-1 ms-3 whitespace-nowrap">Commentaires Horaires : {item.information_complement}</span>
                                </li>

                                <li>
                                    <span
                                        className="flex-1 ms-3 whitespace-nowrap">Retour en arrière HNO : {item.rewind_option ? "Oui" : "Non"}</span>
                                </li>
                                <li>
                                    <span
                                        className="flex-1 ms-3 whitespace-nowrap">Commentaire Opérateur : {item.operator_comment}</span>
                                </li>
                                <li>
                                    <span
                                        className="flex-1 ms-3 whitespace-nowrap">Date Envoi : {item.created_date}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={"flex p-5  justify-center"}>
                    <div className={"flex-auto content-center"}>
                        {(() => {
                            if (elements) {
                                return elements.map((entry) => {
                                    const getType = identifyElement(entry);
                                    if (getType === 'itemCR')
                                        return (<OrderCr item={entry} errorCodes={errorCodes}/>);
                                    else if (getType === 'itemAR')
                                        return (<OrderAr item={entry} errorCodes={errorCodes}/>);
                                    else if (getType === 'itemSuborder')
                                        return (<OrderSuborder item={entry}/>);
                                    else if (getType === 'itemANN')
                                        return (<OrderAnn item={entry}/>);
                                    else if (getType === 'itemCRI')
                                        return (<OrderCri item={entry}/>);
                                    return <></>
                                })
                            }
                        })()}
                    </div>
                </div>
            </div>
        </BaseView>
    );
}

export default OrdersDetail;