import React from "react";
import { createRoot } from 'react-dom/client';

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/index.css";
import "assets/styles/porta.css";
import 'react-toastify/dist/ReactToastify.css';

import App from "app.js";

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab="home" />);
