import React, {useState, useEffect, useCallback} from "react";
import PropTypes from "prop-types";
import {Url} from "../../constants/global";
import useToken from "../App/useToken";

export default function StatusTable({ color }) {
  const { token } = useToken();
  const [list, setList] = useState([]);

  const refreshData = useCallback(() => {
    fetch(Url + '/backend/status',{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token
      }
    }).then(res => res.json()).then((data) => {
      setList(data);
    })
  }, [token])

  useEffect(() => {
    refreshData()
  }, [refreshData])

  const handleClean = () => {
    fetch(Url + '/backend/status',{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token
      }
    }).then(() => {
      refreshData();
    })
  }

  return (
      <div
          className={
              "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
              (color === "light" ? "bg-white" : "bg-sky-900 text-white")
          }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                  className={
                      "font-semibold text-lg " +
                      (color === "light" ? "text-slate-700" : "text-white")
                  }
              >
                Status
                <button
                    className="text-emerald-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleClean}
                >
                  <i className="fas fa-recycle"/>
                </button>
              </h3>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
            <tr>
              <th
                  className={
                      "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (color === "light"
                          ? "bg-slate-50 text-slate-500 border-slate-100"
                          : "bg-sky-800 text-sky-300 border-sky-700")
                  }
              >
                Worker
              </th>
              <th
                  className={
                      "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (color === "light"
                          ? "bg-slate-50 text-slate-500 border-slate-100"
                          : "bg-sky-800 text-sky-300 border-sky-700")
                  }
              >
                Hostname
              </th>
              <th
                  className={
                      "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (color === "light"
                          ? "bg-slate-50 text-slate-500 border-slate-100"
                          : "bg-sky-800 text-sky-300 border-sky-700")
                  }
              >
                Last Response
              </th>
              <th
                  className={
                      "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (color === "light"
                          ? "bg-slate-50 text-slate-500 border-slate-100"
                          : "bg-sky-800 text-sky-300 border-sky-700")
                  }
              >
                Last Saves
              </th>
              <th
                  className={
                      "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (color === "light"
                          ? "bg-slate-50 text-slate-500 border-slate-100"
                          : "bg-sky-800 text-sky-300 border-sky-700")
                  }
              >
                Status
              </th>
              <th
                  className={
                      "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                      (color === "light"
                          ? "bg-slate-50 text-slate-500 border-slate-100"
                          : "bg-sky-800 text-sky-300 border-sky-700")
                  }
              />
            </tr>
            </thead>
            <tbody>
            {list.map((item) => {
              return (
                  <tr key={item.id}>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                      {" "}
                      <span
                          className={
                              "ml-3 font-bold " +
                              +(color === "light" ? "text-slate-600" : "text-white")
                          }
                      >
                    {item.worker}
                  </span>
                    </th>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item.hostname}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item.lastResponse}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {item.lastSave}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <i className="fas fa-circle text-orange-500 mr-2"/> pending
                    </td>
                  </tr>
              )
            })}
            </tbody>
          </table>
        </div>
      </div>
  );
}

StatusTable.defaultProps = {
  color: "light",
};

StatusTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
