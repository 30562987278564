import React, {useEffect, useState} from "react";
import {Url} from "../../constants/global";

// components

import Pagination from "../Pagination/Pagination";
import TableOrderDropdown from "../Dropdowns/TableOrderDropdown";
import BaseView from "../../views/admin/BaseView";
import {useNavigate} from "react-router";


function OrdersTable({color = 'light'}) {
    const [totalOrders, setTotalOrders] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentOrders, setCurrentOrders] = useState([]);
    const pageLimit = 25;
    const [inputValue , setInputValue] = useState("");
    const navigate = useNavigate();

    function getToken() {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken?.token
    }

    useEffect(()=>{
        const getOrders = (inputValue='', currentPage = 1) => {
            fetch(Url + '/order?'+(inputValue!==""?"ref__contains="+inputValue+"&":"")+'order=-created_at&request__in=RESTIT,DEMPORT,DEPORSUB&page='+currentPage+'&limit=' + pageLimit, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getToken()
                }
            }).then(res => res.json()).then((data) => {
                setTotalOrders(data.max);
                setCurrentOrders(data.results);
            })
        }
        getOrders(inputValue, currentPage);

    }, [inputValue, currentPage])

    function onPageChanged(data){
        setCurrentPage(data.currentPage);
    }

    function detailClick(item){
        navigate('/admin/orders/'+item.id);
    }

    const handleInputChange = event =>{
        let inputValue = event.target.value;
        setCurrentPage(1);
        setInputValue(inputValue);
    }

    if (totalOrders === 0) return null;

    return (
        <BaseView>
            <div
                className={
                    "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                    (color === "light" ? "bg-white" : "bg-sky-900 text-white")
                }
            >
                <div className="rounded-t mb-0 px-4 py-3 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                            <div className={"float-right"}>
                                <label>Search : <input onChange={handleInputChange} className={"px-2 py-1 text-slate-600 bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline"} type={"text"} name={"search"} /></label>
                            </div>
                            <h3
                                className={
                                    "font-semibold text-lg " +
                                    (color === "light" ? "text-slate-700" : "text-white")
                                }
                            >
                                Orders
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="block w-full overflow-x-auto">
                    {/* Projects table */}
                    <table className="items-center w-full bg-transparent border-collapse">
                        <thead>
                        <tr>
                            <th
                                className={
                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                    (color === "light"
                                        ? "bg-slate-50 text-slate-500 border-slate-100"
                                        : "bg-sky-800 text-sky-300 border-sky-700")
                                }
                            >
                                Operator
                            </th>
                            <th
                                className={
                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                    (color === "light"
                                        ? "bg-slate-50 text-slate-500 border-slate-100"
                                        : "bg-sky-800 text-sky-300 border-sky-700")
                                }
                            >
                                Ref
                            </th>
                            <th
                                className={
                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                    (color === "light"
                                        ? "bg-slate-50 text-slate-500 border-slate-100"
                                        : "bg-sky-800 text-sky-300 border-sky-700")
                                }
                            >
                                Movement
                            </th>
                            <th
                                className={
                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                    (color === "light"
                                        ? "bg-slate-50 text-slate-500 border-slate-100"
                                        : "bg-sky-800 text-sky-300 border-sky-700")
                                }
                            >
                                Direction
                            </th>
                            <th
                                className={
                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                    (color === "light"
                                        ? "bg-slate-50 text-slate-500 border-slate-100"
                                        : "bg-sky-800 text-sky-300 border-sky-700")
                                }
                            >
                                Request
                            </th>
                            <th
                                className={
                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                    (color === "light"
                                        ? "bg-slate-50 text-slate-500 border-slate-100"
                                        : "bg-sky-800 text-sky-300 border-sky-700")
                                }
                            >
                                Offer Code
                            </th>
                            <th
                                className={
                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                    (color === "light"
                                        ? "bg-slate-50 text-slate-500 border-slate-100"
                                        : "bg-sky-800 text-sky-300 border-sky-700")
                                }
                            >
                                OPE
                            </th>
                            <th
                                className={
                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                    (color === "light"
                                        ? "bg-slate-50 text-slate-500 border-slate-100"
                                        : "bg-sky-800 text-sky-300 border-sky-700")
                                }
                            >
                                OPA
                            </th>
                            <th
                                className={
                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                    (color === "light"
                                        ? "bg-slate-50 text-slate-500 border-slate-100"
                                        : "bg-sky-800 text-sky-300 border-sky-700")
                                }
                            >
                                Client Name
                            </th>
                            <th
                                className={
                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                    (color === "light"
                                        ? "bg-slate-50 text-slate-500 border-slate-100"
                                        : "bg-sky-800 text-sky-300 border-sky-700")
                                }
                            >
                                Status
                            </th>
                            <th
                                className={
                                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                                    (color === "light"
                                        ? "bg-slate-50 text-slate-500 border-slate-100"
                                        : "bg-sky-800 text-sky-300 border-sky-700")
                                }
                            >
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentOrders && currentOrders.map((item) => {
                            return (
                                <tr className="whitespace-normal" key={item.id} data-id={item.id}>
                                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                                        {" "}
                                        <span
                                            className={
                                                "ml-3 font-bold " +
                                                +(color === "light" ? "text-slate-600" : "text-white")
                                            }
                                        >
                                          {item.operator}
                                        </span>
                                    </th>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                                        {item.ref}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                        {item.movement}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                                        {item.porting_out === 0 ? 'IN' : 'OUT'}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                                        {item.request}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                                        {item.offer_code}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                                        {item.ope}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                                        {item.opa}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                                        {item.client_name}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                                        {item.status}
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                                        <TableOrderDropdown onDetailClick={detailClick} item={item}/>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="flex flex-row py-4 align-items-center">
                <Pagination key={totalOrders} totalRecords={totalOrders} pageLimit={pageLimit} pageNeighbours={1}
                            onPageChanged={onPageChanged}/>
            </div>
        </BaseView>
    );
}

export default OrdersTable;