import React from "react";
import PropTypes from "prop-types";

function BaseView({children = []}) {
  return (
      <div className="flex flex-wrap mt-4">
          <div className="w-full mb-12 px-4">
              {children}
          </div>
      </div>
  );
}

BaseView.propTypes = {
    children: PropTypes.node
};

export default BaseView;