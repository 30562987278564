import React, {useCallback} from "react";
import {Link} from "react-router-dom";

import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";
import {Url} from "../../constants/global";
import useToken from "../App/useToken";
import {toast} from "react-toastify";

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const { token } = useToken();

  const manualSic = useCallback(() => {
    fetch(Url + '/backend/manualsic',{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token
      },
      method: 'POST',
      body: ''
    }).then(() => {
      toast.success('Manual SIC launched');
    })
  }, [token])

  return (
      <nav
          className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div
            className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <button
              className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
              type="button"
              onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link
              className="md:block text-left md:pb-2 text-slate-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
              to="/"
          >
            APNF System
          </Link>
          <button
              className="cursor-pointer md:min-w-full text-slate-500 text-xs text-left right uppercase font-bold block pt-1 pb-4 no-underline"
              type="button"
              onClick={() => manualSic()}
          >
            Manual SIC <i className="fas fa-sync"/>
          </button>
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown/>
            </li>
          </ul>
          {/* Collapse */}
          <div
              className={
                  "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
                  collapseShow
              }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-slate-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                      className="md:block text-left md:pb-2 text-slate-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                      to="/"
                  >
                    APNF System
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                      type="button"
                      className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                      onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>

            {/* Divider */}
            <hr className="my-4 md:min-w-full"/>
            {/* Heading */}
            <h6 className="md:min-w-full text-slate-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Global
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                    className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/status") !== -1
                            ? "text-sky-500 hover:text-sky-600"
                            : "text-slate-700 hover:text-slate-500")
                    }
                    to="/admin/status"
                >
                  <i
                      className={
                          "fas fa-tv mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/status") !== -1
                              ? "opacity-75"
                              : "text-slate-300")
                      }
                  ></i>{" "}
                  Status
                </Link>
              </li>

              <li className="items-center">
                <Link
                    className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/audit") !== -1
                            ? "text-sky-500 hover:text-sky-600"
                            : "text-slate-700 hover:text-slate-500")
                    }
                    to="/admin/audit"
                >
                  <i
                      className={
                          "fas fa-table mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/audit") !== -1
                              ? "opacity-75"
                              : "text-slate-300")
                      }
                  ></i>{" "}
                  Audit
                </Link>
              </li>

              <li className="items-center">
                <Link
                    className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/deadletter") !== -1
                            ? "text-sky-500 hover:text-sky-600"
                            : "text-slate-700 hover:text-slate-500")
                    }
                    to="/admin/deadletter"
                >
                  <i
                      className={
                          "fas fa-table mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/deadletter") !== -1
                              ? "opacity-75"
                              : "text-slate-300")
                      }
                  ></i>{" "}
                  DeadLetter
                </Link>
              </li>
            </ul>

            {/* Divider */}
            <hr className="my-4 md:min-w-full"/>
            {/* Heading */}
            <h6 className="md:min-w-full text-slate-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Orders
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                    className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/orders") !== -1
                            ? "text-sky-500 hover:text-sky-600"
                            : "text-slate-700 hover:text-slate-500")
                    }
                    to="/admin/orders"
                >
                  <i
                      className={
                          "fas fa-tv mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/orders") !== -1
                              ? "opacity-75"
                              : "text-slate-300")
                      }
                  ></i>{" "}
                  Orders
                </Link>
              </li>
            </ul>

            {/* Divider */}
            <hr className="my-4 md:min-w-full"/>
            {/* Heading */}
            <h6 className="md:min-w-full text-slate-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Webhooks
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                    className={
                        "text-xs uppercase py-3 font-bold block " +
                        (window.location.href.indexOf("/admin/webhooks") !== -1
                            ? "text-sky-500 hover:text-sky-600"
                            : "text-slate-700 hover:text-slate-500")
                    }
                    to="/admin/webhooks"
                >
                  <i
                      className={
                          "fas fa-tv mr-2 text-sm " +
                          (window.location.href.indexOf("/admin/webhooks") !== -1
                              ? "opacity-75"
                              : "text-slate-300")
                      }
                  ></i>{" "}
                  Webhooks
                </Link>
              </li>
            </ul>

            {/* Divider */}
            <hr className="my-4 md:min-w-full"/>
            {/* Heading */}
            <h6 className="md:min-w-full text-slate-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Users
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              <li className="items-center">
                <Link
                    className="text-slate-700 hover:text-slate-500 text-xs uppercase py-3 font-bold block"
                    to="/admin/users"
                >
                  <i className="fas fa-fingerprint text-slate-400 mr-2 text-sm"></i>{" "}
                  Users
                </Link>
              </li>

              <li className="items-center">
                <Link
                    className="text-slate-700 hover:text-slate-500 text-xs uppercase py-3 font-bold block"
                    to="/admin/usergroups"
                >
                  <i className="fas fa-clipboard-list text-slate-300 mr-2 text-sm"></i>{" "}
                  Group
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
  );
}
